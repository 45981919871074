import { faEllipsisH } from "@fortawesome/free-solid-svg-icons/faEllipsisH";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";
import { Link } from "react-router-dom";

import tagActions from "actions/tag";
import {
  selectSpecificCategory,
  selectCategoryLoading,
  selectCategoryFailed,
} from "selectors/category";
import getCategoryText from "utils/entity/getCategoryText";
import getCategoryUrl from "utils/entity/getCategoryUrl";
import generateTransition from "utils/generateTransition";

import useActionCreators from "hooks/useActionCreators";
import useLoadEntity from "hooks/useLoadEntity";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  creatorMore: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontSize: ".9rem",
    color: "var(--color-neutral-d4)",
    backgroundColor: "#DFE1E6",
    borderRadius: "50%",
  },
  category: {
    ...gStyles.avalonBold,
    display: "inline-block",
    padding: ".2rem .8125rem",
    color: "var(--color-neutral-d4)",
    backgroundColor: "#edeff4",
    marginRight: "0.375rem",
    fontSize: ".75rem",
    borderRadius: "3em",
    transition: generateTransition({
      target: "visual",
      options: { speed: "200ms" },
    }),

    ":hover": {
      color: colours.cardCategoryHoverText,
      backgroundColor: "#d4d6e2",
    },
    ":focus": {
      color: colours.cardCategoryHoverText,
      backgroundColor: "#d4d6e2",
    },

    [ScreenSizes.mdAndAbove]: {
      fontSize: "0.75rem",
      marginRight: "1em",
    },
  },
  categoryAsLink: {
    ...gStyles.fontBold,
  },
};

const CategoryTag = (props) => {
  const { isMore, to, slug, asLink, title, onClick, height, gather } = props;

  const customStyles = {
    category: {
      ...(height && {
        display: "flex",
        alignItems: "center",
        paddingTop: "0.25em",
        lineHeight: 1,
        height,
      }),
      ...(gather && {
        background: "#fff",
        border: "1px solid var(--color-neutral-l2)",
        borderRight: "none",
        borderRadius: 0,
        marginRight: 0,
        ":first-child": {
          borderTopLeftRadius: "3em",
          borderBottomLeftRadius: "3em",
          paddingLeft: "1em",
        },
        ":last-child": {
          borderTopRightRadius: "3em",
          borderBottomRightRadius: "3em",
          paddingRight: "1em",
          borderRight: "1px solid var(--color-neutral-l2)",
        },
        ":hover": {
          backgroundColor: "var(--color-neutral-l1)",
        },
        ":focus": {
          backgroundColor: "var(--color-neutral-l1)",
        },
        [ScreenSizes.mdAndAbove]: {
          marginRight: 0,
        },
      }),
    },
  };

  const { styles } = useStyles([baseStyles, customStyles], props);

  const slugIsMap = Map.isMap(slug);
  const slugIsString = typeof slug === "string";

  const passedCategory = slugIsMap ? slug : null;

  const { loadSpecificTag } = useActionCreators({
    loadSpecificTag: tagActions.loadSpecificTag,
  });

  const { entity: pulledCategory } = useLoadEntity({
    entity_type: "category",
    entity_id: slugIsMap ? slug.get("slug") : slug,
    loader: loadSpecificTag,
    selector: selectSpecificCategory,
    loadingSelector: selectCategoryLoading,
    failedSelector: selectCategoryFailed,
  });

  const category = pulledCategory || passedCategory || slug;
  const categoryText = useMemo(() => getCategoryText(category), [category]);

  if (!category && !slugIsString) {
    return null;
  }

  if (isMore) {
    return (
      <Link
        key="categoryMore"
        onClick={onClick}
        to={onClick ? null : to}
        className={css(styles.category, styles.categoryMore)}
        aria-label="Show More Categories"
      >
        <FontAwesomeIcon icon={faEllipsisH} />
      </Link>
    );
  }

  if (!categoryText) {
    return null;
  }

  return (
    <Link
      to={getCategoryUrl(category || slug)}
      className={css(asLink ? styles.categoryAsLink : styles.category)}
      title={title || `${categoryText} Podcasts`}
    >
      {categoryText}
    </Link>
  );
};

CategoryTag.propTypes = {
  isMore: PropTypes.bool,
  to: PropTypes.string,
  slug: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.string]),
  asLink: PropTypes.bool,
  title: PropTypes.node,
  onClick: PropTypes.func,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  gather: PropTypes.bool,
};

CategoryTag.defaultProps = {
  isMore: false,
  to: null,
  slug: null,
  asLink: false,
  title: null,
  onClick: null,
  height: null,
  gather: false,
};

export default memo(CategoryTag);
