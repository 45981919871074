import { css } from "aphrodite";
import PropTypes from "prop-types";

import EntityImage from "components/Entities/EntityImage";
import EntityLink from "components/Entities/EntityLink";
import BadgeContainer from "components/Tastemaker/BadgeContainer";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: ".4rem",
    zIndex: 2,
    maxHeight: "1.925rem",
  },
  titleContainerNoContent: {
    marginBottom: 0,
  },
  userImage: {
    width: "1.8rem",
    maxWidth: "1.8rem",
    height: "1.8rem",
    marginRight: ".75rem",
  },
  reviewBubbleTitleContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    lineHeight: "1.5rem",
    alignItems: "center",
  },
  reviewBubbleTitle: {
    marginRight: ".5rem",
  },
};

const badgeContainerStyles = {
  badgeContainer: {
    left: "-0.25rem",
    bottom: "-0.25rem",
    cursor: "auto",
  },
  badge: {
    width: "0.813rem",
    height: "0.813rem",
  },
  userProfileImage: {
    padding: "0.125rem",
  },
};

const BubbleHeader = (props) => {
  const {
    showInfoIcons,
    showTitle,
    showImage,
    content,
    authorEntityType,
    authorEntity,
    onTitleContainerClick,
    inlineWithTitle,
    children,
  } = props;

  const { styles } = useStyles(baseStyles, props);

  return (
    <div
      className={css(
        styles.titleContainer,
        !showInfoIcons && !content && styles.titleContainerNoContent
      )}
      onClick={onTitleContainerClick}
    >
      <div className={css(styles.reviewBubbleTitleContainer)}>
        {showImage && (
          <div className={css(styles.userImage)}>
            <BadgeContainer
              styles={badgeContainerStyles}
              userId={authorEntity?.get("id")?.toString()}
            >
              <EntityImage
                entity_type={authorEntityType}
                entity={authorEntity}
                size={36}
                fullWidth
                imgProps={{
                  renderOnMount: true,
                }}
              />
            </BadgeContainer>
          </div>
        )}
        {showTitle && (
          <div className={css(styles.reviewBubbleTitle)}>
            <EntityLink entity_type={authorEntityType} entity={authorEntity} />
          </div>
        )}
        {inlineWithTitle}
      </div>
      {children}
    </div>
  );
};

BubbleHeader.propTypes = {
  content: PropTypes.node,
  showInfoIcons: PropTypes.bool,
  showTitle: PropTypes.bool,
  showImage: PropTypes.bool,
  onTitleContainerClick: PropTypes.func,
  authorEntity: PropTypes.object,
  authorEntityType: PropTypes.string,
  inlineWithTitle: PropTypes.node,
  children: PropTypes.node,
};

BubbleHeader.defaultProps = {
  content: null,
  showInfoIcons: false,
  showTitle: false,
  showImage: false,
  authorEntityType: "user",
  authorEntity: null,
  onTitleContainerClick: null,
  inlineWithTitle: null,
  children: null,
};

export default BubbleHeader;
