import { loadSpecificTag } from "routines/tags";

import * as constants from "constants/tag";

const tagActions = {
  saveEntityTags: ({ entity_id, entity_type, tags }) => ({
    type: constants.SAVE_TAGS_REQUEST,
    entity_id,
    entity_type,
    tags,
  }),
  getTagSuggestions: ({ text, tagType }) => ({
    type: constants.GET_TAG_SUGGESTIONS,
    tagType,
    text,
  }),
  loadSpecificTag: (tag_slug) => ({
    type: loadSpecificTag.TRIGGER,
    payload: { tag_slug },
  }),
};

export default tagActions;
