import { css } from "aphrodite";
import { fromJS, List, Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback, useContext, useMemo, useState } from "react";

import ReplyBubble from "./ReplyBubble";
import ReviewCardContext from "./ReviewCardContext";
import ReviewStub from "./ReviewStub";

import getReviewUrl from "utils/entity/getReviewUrl";

import useReviewWithEntity from "hooks/useReviewWithEntity";
import { useStyles } from "hooks/useStyles";

const baseStyles = {
  reviewReplies: {
    display: "flex",
    flexDirection: "column",
    marginTop: "0.5rem",
    paddingLeft: "0.75rem",
  },
  reviewReply: {},
  reviewRepliesStub: {
    dipslay: "flex",
  },
};

const ReviewReplies = (props) => {
  const { collapsable } = props;
  const { styles } = useStyles(baseStyles, props);

  const [expanded, setExpanded] = useState(false);
  const {
    entity,
    insideCarousel,
    review: contextReview,
  } = useContext(ReviewCardContext);
  const review = useReviewWithEntity(contextReview && contextReview.get("id"));

  const replies = useMemo(() => {
    if (review) {
      return fromJS(review.get("has_reply") ? [review.get("reply")] : []);
    }

    return List([]);
  }, [review]);

  const onExpandToggle = useCallback(() => {
    setExpanded(!expanded);
  }, [setExpanded, expanded]);

  const renderReply = (reply, index) => (
    <span className={css(styles.reviewReply)}>
      <ReplyBubble
        {...props}
        key={reply.get("position")}
        review={entity}
        reply={reply}
        index={index}
        isLast={index + 1 === replies.size}
        onTitleContainerClick={onExpandToggle}
      />
    </span>
  );

  const renderReplies = () => (
    <div className={css(styles.reviewReplies)}>{replies.map(renderReply)}</div>
  );

  const renderStub = () => (
    <ReviewStub
      replies={replies}
      onToggle={insideCarousel ? undefined : onExpandToggle}
      link={insideCarousel && review ? getReviewUrl(review, true) : undefined}
    />
  );

  return expanded || !collapsable ? renderReplies() : renderStub();
};

ReviewReplies.propTypes = {
  entity: PropTypes.instanceOf(Map).isRequired,
};

ReviewReplies.defaultProps = {
  collapsable: false,
};

export default memo(ReviewReplies);
