import { css } from "aphrodite";
import PropTypes from "prop-types";
import { useCallback, useContext } from "react";
import BaseDotdotdot from "react-dotdotdot";

import colours from "../../../../../styles/colours";
import ReviewCardContext from "./ReviewCardContext";

import modalActions from "actions/modals";
import ratingActions from "actions/rating";

import useActionCreators from "hooks/useActionCreators";
import useLoggedInUser from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";

import cardStyles, { cardSizes } from "styles/CardStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  reviewBubbleContent: {
    ...cardStyles.mobileCardDescription,
    whiteSpace: "pre-wrap",
    maxHeight: "initial",
    cursor: "pointer",
    display: "-webkit-box",
    "-webkit-line-clamp": "5",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    wordBreak: "break-word",

    [ScreenSizes.mdAndAbove]: {
      "-webkit-line-clamp": "3",
    },

    [ScreenSizes.lgAndAbove]: {
      "-webkit-line-clamp": "10",
    },
  },
  rateReviewBubble: {
    position: "relative",
  },
  rateReviewBubbleWithLikeBadge: {},
  rateReviewBubbleArrow: {
    backgroundColor: colours.reviewBubbleBackground,
    height: 20,
    width: 25,
    position: "absolute",
    left: "-1px",
    top: 0,
    transform: "rotate(75deg)",
    borderRadius: "102% 0 110%",
  },
  rateReviewBubbleInner: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "14px",
    backgroundColor: colours.reviewBubbleBackground,
    padding: cardSizes.mobileReviewBubblePadding,

    [ScreenSizes.mdAndAbove]: {
      padding: cardSizes.reviewBubblePadding,
    },
  },
  rateReviewBubbleInnerWithArrow: {
    borderTopLeftRadius: 0,
    marginLeft: 3,
  },
  rateReviewBubbleInnerWithLikeBadge: {},
  rateReviewBubbleInnerNoContent: {
    borderRadius: "5rem",
    padding: cardSizes.mobileReviewBubblePaddingNoContent,

    [ScreenSizes.mdAndAbove]: {
      padding: cardSizes.reviewBubblePaddingNoContent,
    },
  },
};

const levelStyles = {
  2: {
    rateReviewBubbleInner: {
      background: "none",
      padding: 0,
      marginBottom: 0,
    },
    reviewBubbleContent: {
      cursor: "default",
    },
  },
};

const Bubble = (props) => {
  const {
    showArrow,
    showBubble,
    showLikesBadge,
    showInfoIcons,
    level,
    content,
    header,
    footer,
  } = props;

  const { styles } = useStyles(
    [baseStyles, level && levelStyles[level]],
    props
  );
  const loggedInUser = useLoggedInUser();
  const { user, review } = useContext(ReviewCardContext);

  const { openEpisodeRatingModal, openPodcastRatingModal, showModal } =
    useActionCreators({
      openEpisodeRatingModal: ratingActions.openEpisodeRatingModal,
      openPodcastRatingModal: ratingActions.openPodcastRatingModal,
      showModal: modalActions.showModal,
    });

  const byLoggedInUser =
    user && loggedInUser && user.get("id") === loggedInUser.get("id");

  const handleOpenReviewEditModal = useCallback(() => {
    const reviewEntity = review.get("entity");
    const reviewEntityType = review.get("entity_type");
    const entity_id = review.get("entity_id");

    if (reviewEntityType === "episode") {
      openEpisodeRatingModal(entity_id, {
        entity: reviewEntity,
        initialReview: review.get("content"),
      });
    } else if (reviewEntityType === "podcast") {
      openPodcastRatingModal(entity_id, {
        entity: reviewEntity,
        initialRating: review.get("rating"),
      });
    }
  }, [review, openPodcastRatingModal, openEpisodeRatingModal]);

  const handleReviewBubbleClick = useCallback(
    (e) => {
      if (byLoggedInUser && level === 1) {
        handleOpenReviewEditModal(e);
      } else {
        showModal("review", { review_id: review.get("id") });
      }
    },
    [byLoggedInUser, handleOpenReviewEditModal, level, review, showModal]
  );

  return (
    <div
      className={css(
        styles.rateReviewBubble,
        showLikesBadge && styles.rateReviewBubbleWithLikeBadge
      )}
      data-id="review_bubble"
    >
      {showBubble && showArrow && (
        <div className={css(styles.rateReviewBubbleArrow)} />
      )}
      <div
        className={css(
          showBubble && styles.rateReviewBubbleInner,
          !showInfoIcons && !content && styles.rateReviewBubbleInnerNoContent,
          showArrow && styles.rateReviewBubbleInnerWithArrow,
          showLikesBadge && styles.rateReviewBubbleInnerWithLikeBadge
        )}
      >
        {header}
        {content && (
          <div onClick={handleReviewBubbleClick}>
            <BaseDotdotdot
              clamp={10}
              tagName="div"
              useNativeClamp
              className={css(styles.reviewBubbleContent)}
            >
              {content && typeof content === "string"
                ? content.trim()
                : content}
            </BaseDotdotdot>
          </div>
        )}
        {footer}
      </div>
    </div>
  );
};

Bubble.propTypes = {
  header: PropTypes.node,
  footer: PropTypes.node,
  showArrow: PropTypes.bool,
  showBubble: PropTypes.bool,
  showLikesBadge: PropTypes.bool,
  content: PropTypes.node,
  showInfoIcons: PropTypes.bool,
  level: PropTypes.number,
};

Bubble.defaultProps = {
  header: null,
  footer: null,
  showBubble: false,
  showArrow: false,
  showLikesBadge: true,
  content: null,
  showInfoIcons: false,
  level: 1,
};

export default Bubble;
