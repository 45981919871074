import { css } from "aphrodite";
import PropTypes from "prop-types";
import { useContext, useMemo } from "react";

import InfoIcons from "components/Entities/Items/InfoIconsAsync";

import { getIcons } from "../sharedReviewElements";
import ReviewCardContext from "./ReviewCardContext";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  actionButtons: {
    position: "absolute",
    top: "100%",
    right: 12,
    marginTop: -10,
    display: "flex",
    flexDirection: "row",
  },
  actionButtonsWithInfoIcons: {
    position: "relative",
    margin: 0,
    top: "auto",
    right: "auto",
    marginLeft: "auto",
  },
  reviewInfoIconRow: {
    margin: "0.75rem 0 0",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    minHeight: "1.5rem",
  },
};

const levelStyles = {
  2: {
    actionButtonsWithInfoIcons: {
      marginLeft: "0.5rem",
    },
  },
};

const BubbleActions = (props) => {
  const { showInfoIcons, level, show, children } = props;
  const { styles } = useStyles(
    [baseStyles, level && levelStyles[level]],
    props
  );
  const { review, entity } = useContext(ReviewCardContext);
  const icons = useMemo(() => getIcons(review, entity), [entity, review]);

  const renderActionButtons = () => {
    if (show) {
      return (
        <div
          className={css(
            styles.actionButtons,
            showInfoIcons && styles.actionButtonsWithInfoIcons
          )}
        >
          {children}
        </div>
      );
    }
    return null;
  };

  if (showInfoIcons) {
    return (
      <div className={css(styles.reviewInfoIconRow)}>
        <InfoIcons icons={icons} />
        {renderActionButtons()}
      </div>
    );
  }

  return renderActionButtons();
};

BubbleActions.propTypes = {
  showInfoIcons: PropTypes.bool,
  level: PropTypes.number,
  show: PropTypes.bool,
  children: PropTypes.node,
};

BubbleActions.defaultProps = {
  showInfoIcons: false,
  level: 1,
  show: false,
  children: null,
};

export default BubbleActions;
