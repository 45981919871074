import getReviewUrl from "utils/entity/getReviewUrl";

export const getIcons = (review) =>
  review
    ? [
        review.get("created_at") && {
          type: "reviewed",
          value: review.get("created_at"),
          to: getReviewUrl(review, true),
        },
      ].filter((iconType) => iconType)
    : [];
