import PropTypes from "prop-types";

import LazyLoadComponent from "components/Common/LazyLoad/LazyLoadComponent";

import ReviewBubble from "./Bubble/ReviewBubble";
import ReviewBubbleProvider from "./Bubble/ReviewBubbleProvider";

const Review = (props) => {
  const { renderOnMount } = props;

  const review = (
    <ReviewBubbleProvider {...props}>
      <ReviewBubble {...props} />
    </ReviewBubbleProvider>
  );

  return (
    <LazyLoadComponent renderOnMount={renderOnMount} height={50}>
      {review}
    </LazyLoadComponent>
  );
};

Review.propTypes = {
  renderOnMount: PropTypes.bool,
};
Review.defaultProps = {
  renderOnMount: false,
};

export default Review;
