import { faReply } from "@fortawesome/pro-solid-svg-icons/faReply";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { memo, Fragment, useContext, useMemo } from "react";

import ReviewCardContext from "./ReviewCardContext";

import getPodcastName from "utils/entity/getPodcastName";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  reviewRepliesPrompt: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "12px 0 0 16px",
    fontSize: "0.8125rem",
    cursor: "pointer",
  },
  promptImage: {
    display: "block",
    width: 24,
    height: 24,
    marginRight: "0.75rem",
    flex: "0 0 auto",
    alignSelf: "flex-start",
  },
  content: {
    ...gStyles.fontSemiBold,
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    flex: "0 1 auto",
    overflow: "hidden",
    lineHeight: 1.6,
  },
  replyIcon: {
    marginRight: "0.5em",
  },
};

const ReviewPrompt = (props) => {
  const { styles } = useStyles(baseStyles, props);

  const { podcast, onToggleInput } = useContext(ReviewCardContext);

  const entityName = useMemo(() => getPodcastName(podcast), [podcast]);

  return (
    <Fragment>
      <div className={css(styles.reviewRepliesPrompt)} onClick={onToggleInput}>
        <span className={css(styles.content)}>
          <span>
            <span className={css(styles.replyIcon)}>
              <FontAwesomeIcon icon={faReply} />
            </span>
            {`Reply as ${
              entityName && entityName.length < 20 ? entityName : "your podcast"
            }`}
          </span>
        </span>
      </div>
    </Fragment>
  );
};

export default memo(ReviewPrompt);
