import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, Fragment, useContext } from "react";

import Bubble from "./Bubble";
import BubbleActions from "./BubbleActions";
import BubbleHeader from "./BubbleHeader";
import ReviewCardContext from "./ReviewCardContext";

import useEntity from "hooks/useEntity";

const ReplyBubble = (props) => {
  const { reply, ...rest } = props;

  const { confirmComponent } = useContext(ReviewCardContext);

  const { entity: replier } = useEntity(
    reply.get("reply_as_entity_type"),
    reply.get("reply_as_entity_id")
  );

  return (
    <Fragment>
      <Bubble
        {...rest}
        content={reply.get("content")}
        level={2}
        header={
          <BubbleHeader
            {...props}
            authorEntity={replier}
            authorEntityType={reply.get("reply_as_entity_type")}
          />
        }
        footer={<BubbleActions {...props} />}
      />
      {confirmComponent}
    </Fragment>
  );
};

ReplyBubble.propTypes = {
  reply: PropTypes.instanceOf(Map).isRequired,
  parentReview: PropTypes.instanceOf(Map).isRequired,
  index: PropTypes.number,
  isLast: PropTypes.bool,
};

ReplyBubble.defaultProps = {
  index: 0,
  isLast: false,
};

export default memo(ReplyBubble);
